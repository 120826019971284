<template>
  <section class="content">
    <div class="table-responsive">
    <table class="table table-hover" ref="tbllogbuku">
      <thead>
        <tr>
          <th>TANGGAL</th>
          <th>STAFF</th>
          <th>KETERANGAN</th>
          <th>TINDAKAN</th>
        </tr>
      </thead>
      <tbody @click="handleClick"></tbody>
    </table>
    </div>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form role="form" @submit.prevent="submitForm">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p v-if="errors.length" class="alert alert-danger alert-dismissible fade show" role="alert">
                <b>Please correct the following error(s):</b>
                <ul>
                  <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                </ul>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              </p>
              <div class="row">
                <!-- <div class="col-md-12 form-group">
                  <label class="control-label">STAFF</label>
                  <v-select
                    label="name"
                    name="name"
                    :filterable="false"
                    :options="staffPic"
                    v-model="form.staff_id"
                    :reduce="(opt) => opt.id"
                    @search="onSearch"
                    placeholder="Ketik nama/code/no. KTP"
                  >
                    <template slot="no-options"> Tidak ada... </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nama_staff }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nama_staff }}
                      </div>
                    </template>
                  </v-select>
                </div> -->
                <div class="col-md-6 form-group">
                <label class="control-label">STAFF</label>
                  <v-select :options="optStaff" v-model="form.staff_id" :reduce="opt => opt.id" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">TANGGAL</label>
                  <datepicker v-model="form.tanggal" id="tanggal" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">KETERANGAN</label>
                  <textarea
                    class="form-control"
                    id="keterangan"
                    rows="3"
                    v-model="form.keterangan"
                    placeholder="Minimal 20 karakter"
                  ></textarea>
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">TINDAKAN</label>
                  <textarea
                    class="form-control"
                    id="tindakan"
                    rows="3"
                    v-model="form.tindakan"
                    placeholder="Minimal 20 karakter"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">
                Save changes
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import datepicker from "@/components/Datepicker";
import moment from "moment";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";

export default {
  name: "",
  data() {
    return {
      errors: [],
      optStaff: [],
      staffPic: [],
      method: "",
      roles: "",
      formTitle: "Tambah Log Buku",
      form: {
        tanggal: "",
        keterangan: "",
        tindakan: "",
        staff_id: "",
      },
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);

    // this.loadStaff();
    authFetch('/management/log_buku/staff')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
            this.optStaff = js.data;
    })
  },
  methods: {
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
    },
    loadStaff() {
      authFetch("/pr/proposal_lpj_kegiatan/staff").then((res) => {
        res.json().then((json) => {
          //get default address
          if (json.success) {
            this.staffPic.push({
              id: json.data.staff_pic,
              nama_staff: json.data.nama_staff,
            });
          }
        });
      });
    },
    onSearch(search, loading) {
      if (search.length > 3) {
        loading(true);
        var vm = this;
        authFetch(
          `/pr/proposal_lpj_kegiatan/cari_staff?q=${escape(search)}`
        ).then((res) => {
          res.json().then((json) => (vm.staffPic = json.items));
          loading(false);
        });
      }
    },
    submitForm: function (ev) {
      const e = this.$refs;

      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");
      var urlSubmit = "/management/log_buku";
      if (this.method == "PUT")
        urlSubmit = "/management/log_buku/" + this.form.id;

      authFetch(urlSubmit, {
        method: this.method,
        body: data,
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.success) {
            console.log(js.details);

            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          }
          this.table.api().ajax.reload();
          $(e.formDialog).modal("hide");
        });

      ev.preventDefault();
    },
  },
  components: {
    datepicker,
    datePicker,
    vSelect,
  },
  mounted() {
    const e = this.$refs;
    let self = this;
    this.table = createTable(e.tbllogbuku, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/management/log_buku",
      columns: [
        { data: "tanggal", width: "20px" },
        { data: "staff" },
        { data: "keterangan" },
        { data: "tindakan" },
      ],
      filterBy: [1],
      rowCallback: function (row, data) {},
      buttonClick: (evt) => {
        if (evt.role == "create") {
          self.form = {};
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah Log Buku";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          self.form = evt.data;
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit Log Buku";
          $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          // self.form = evt.data;
          console.log(evt);
          Swal.fire({
            title: "Hapus data?",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
          }).then((result) => {
            if (result.isDenied) {
              authFetch("/management/log_buku/" + evt.data.id, {
                method: "DELETE",
                body: "id=" + evt.data.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  this.table.api().ajax.reload();
                });
            }
          });
        }
      },
    });
  },
};
</script>
